$.lui("part", ".part_form_search", function(selector) {
    let refs = {
      autocompleteArea: selector.find(`[data-ref="autocomplete-area"]`)
    };

    let timeout;

    selector.on("input", `[data-input="search"]`, function() {
        clearTimeout(timeout);
        selector.find(`[data-ref="autocomplete-area"]`).addClass("state--active");

        let query = $(this).val();

        if(query.length > 2) {
            $.ajax({
                url: $(this).attr('data-ajax-link'),
                data: {query: query},
                method: 'post',
                dataType: 'json'
            }).done(function (payload) {
                fn_ajaxHandler(payload);
            });
        } else {
            selector.find(`[data-ref="autocomplete-area"]`).removeClass("state--active");
        }
    });

    doc.on("click.search-autocomplete", function (e) {
        if (selector.length && refs.autocompleteArea.hasClass("state--active")) {
            if ($(e.target).closest(selector).length === 0) {
                refs.autocompleteArea.addClass("state--hiding");
                timeout = setTimeout(function() {
                    refs.autocompleteArea.removeClass("state--active state--hiding");
                },300);
            }
        }
    });
});