$.lui("comp",".comp_package_basket",function (selector) {

    //data-add-voucher
    $(document).on('keypress',function(e) {
        if(e.which === 13) {
            if($('[data-voucher-code]').is(':focus')) {
                e.preventDefault();
                $('[data-add-voucher]').trigger('click');
            }
        }
    });

    selector.on("change", "[data-toggle] input", function() {
        let row = $(this).closest("[data-toggle]").next(".elm_form_row");
        if ($(this).prop("checked") === true) {
            row.slideDown(300);
        } else {
            row.slideUp(300);
        }
    });

    selector.on('change','input[type="number"][data-ajax-link]',function() {
        let ajaxLink = $(this).attr('data-ajax-link');
        let count = $(this).val();

        $.ajax({
            url: ajaxLink,
            data: {count: count},
            method: 'post',
            dataType: 'json'
        }).done(function (payload) {
            fn_ajaxHandler(payload);
        });
    });

    selector.on('click','[data-add-voucher]',function() {

        let url = $(this).attr('data-ajax-link');
        let code = $('input[data-voucher-code]').val();

        $.ajax({
            url: url,
            method: 'post',
            dataType: 'json',
            cache: false,
            data: {
                code: code
            }
        }).done(function (payload) {
            fn_ajaxHandler(payload);
        });

    });

    selector.on('change','[data-service-item]',function() {
        let ajaxLink = $(this).attr('data-ajax-link');
        let ajaxData = {};

        if($('[data-service-type="ZASILKOVNA"]').length && $('[data-service-type="ZASILKOVNA"]').prop('checked') === true) {
            let pointId = $('.packeta-selector-branch-id').val();
            let deliveryId = $('[data-service-type="ZASILKOVNA"]').attr('value');
            let pointName = $('input[name="pickup_point_name_' + deliveryId + '"]').val();

            ajaxData = {
                deliveryId: deliveryId,
                pointId: pointId,
                pointName: pointName
            };
        }

        $.ajax({
            url: ajaxLink,
            method: 'post',
            dataType: 'json',
            data : ajaxData,
        }).done(function (payload) {
            fn_ajaxHandler(payload, function() {
                showHideDeliveryText();
            });
        });
    });

    function showHideDeliveryText()
    {

        $('[data-service-item-text]').fadeOut('fast');

        let checkedItem = $('[data-service-item]:checked');

        if(checkedItem.length) {
            let selectedId = $('[data-service-item]:checked').val();

            $('[data-service-item-text="' + selectedId + '"]').fadeIn('fast');

            let serviceType = checkedItem.attr('data-service-type');
        }
    }
    showHideDeliveryText();

});