let priceRangeBackToDefaults;
let doCatalogFilter;

$.lui("part", ".part_filter", function(selector) {

    priceRangeBackToDefaults = function()
    {
        let defaultMin = $('input[name="priceMinDefault"]').val();
        let defaultMax = $('input[name="priceMaxDefault"]').val();
        $('[data-range-input="min"]').val(defaultMin).change();
        $('[data-range-input="max"]').val(defaultMax).change();
    };

    doCatalogFilter = function(redrawFiltersOnly,page,pageFrom,pagingType,fromSession,callback)
    {
        let filterValues = {};
        $('[data-catalog-filter]').each(function() {
            let name = $(this).attr('name');
            let value = $(this).val();

            filterValues[name] = value;
        });

        $('[data-catalog-filter-check]').each(function() {
            if($(this).prop('checked') === true) {

                let name = $(this).attr('data-name');
                let value = $(this).val();
                if(typeof filterValues[name] === 'undefined') {
                    filterValues[name] = [];
                }
                filterValues[name].push(value);
            }
        });

        let sortType = $('.state--active[data-sort]').attr('data-sort');

        let searchQuery = null;
        searchQuery = $('.comp_products_category').attr('data-search-query');

        let data = {
            filterData: filterValues,
            onlyFilters: (redrawFiltersOnly ? 1 : 0),
            page: (page ? page : 0),
            pageFrom: (pageFrom ? pageFrom : page),
            fromSession: (fromSession ? fromSession : 0),
            pagingType: (pagingType ? pagingType : 'default'),
            sortType: sortType,
            searchQuery: searchQuery,
            inDialog: selector.attr('data-in-filter')
        };

        let dataUri = ['page=' + data.page, 'pageFrom=' + data.pageFrom];

        $.ajax({
            url: selector.attr('data-ajax-link'),
            method: 'post',
            dataType: 'json',
            cache: false,
            data: data
        }).done(function (payload) {
            window.history.replaceState("", "", '?' + dataUri.join('&'));
            fn_ajaxHandler(payload,function() {
                //ScrollReveal().sync();
                callback();
                if(pagingType !== 'append') {
                    $('html, body').animate({
                        scrollTop: $('.comp_products_category').offset().top - $('#layout_header').outerHeight()
                    }, 800);
                }
            });
        });
    };

    let range = selector.find(".part_ui_range");
    if (range.length) {
        range.each(function () {
            let elm = $(this);
            $.importScript(cdnjs.autoNumeric, function () {
                $.importScript(cdnjs.noui, function () {
                    elm.lib_noui({
                        elm_min: elm.find(`[data-range-input="min"]`),
                        elm_max: elm.find(`[data-range-input="max"]`),
                        elm_min_hidden: elm.find(`[data-range-input="min"]`).next(),
                        elm_max_hidden: elm.find(`[data-range-input="max"]`).next(),
                        unit: elm.data("unit")
                    });

                    elm.on("change", `[data-range-input="min"]`, function () {
                        let min = $(this).val();
                        let max = elm.find(`[data-range-input="max"]`).val();
                        elm.closest(".elm_item").find("[data-range-value]").text(`${min.replace(",-", "")} - ${max.replace(",-", "")}`);
                    });
                    elm.on("change", `[data-range-input="max"]`, function () {
                        let min = elm.find(`[data-range-input="min"]`).val();
                        let max = $(this).val();
                        elm.closest(".elm_item").find("[data-range-value]").text(`${min.replace(",-", "")} - ${max.replace(",-", "")}`);
                    });
                });
            });
        });
    }

    selector.on('change','[data-do-filter-change]',function() {
        doCatalogFilter(0,1);
    });

    selector.on('change','[data-catalog-filter-check]',function() {
        doCatalogFilter(0,1);
    });

    selector.on('click','[data-cancel-filters]',function(e) {
        e.preventDefault();
        $('[data-sort]').removeClass('state--active');
        $('[data-sort="recommended"]').addClass('state--active');

        $('[data-catalog-filter-check]').prop('checked',false);
        priceRangeBackToDefaults();

        doCatalogFilter(0,1);
    });

    selector.on("click", "[data-toggle]", function() {
        let elm = $(this);
        if (!elm.hasClass("state--active")) {
            elm.addClass("state--active");
            elm.next().slideDown(300);
        } else {
            elm.removeClass("state--active");
            elm.next().slideUp(300);
        }
    });
});