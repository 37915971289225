(function($) {
    var reInitGCaptcha;

    reInitGCaptcha = function() {
        $.getScript("https://www.google.com/recaptcha/api.js?render=6Leyr-MUAAAAAOYKdfU54TLAX1QuqDdTZvTwyWHP").done(function () {
            grecaptcha.ready(function () {
                grecaptcha.execute('6Leyr-MUAAAAAOYKdfU54TLAX1QuqDdTZvTwyWHP', {action: 'form'})
                    .then(function (token) {
                        $('#gtoken').val(token);
                    });
            });
        });
    };

    if ($('form[data-recaptcha]').length) {
        console.log('1');
        reInitGCaptcha();
        setInterval(function(){reInitGCaptcha();}, 150000);
    }

    doc.on('lazybeforeunveil', '.lazyload', function(e){
        if ($(e.target).parent().hasClass("lib--parallax")) {
            $(e.target).parent().lui_parallax();
        }

    });

    lui_dialog.init(lui_dialog_callback);

    $.lui("lib_ripple", ".part_ui_btn", function(selector) {
        selector.lui_ripple();
    });

    $.lui("lib_tabs", "[data-lib-tabs]", function(selector) {
        selector.lui_tabs();
    });

    $.lui("lib_flickity", "[data-lib-flickity]", function(selector) {
        $.lib_flickity(function(){
            selector.each(function(){
                let options = $(this).data("lib-flickity");
                if (options["fade"] === true) {
                    $(this).flickity({
                        imagesLoaded: false,
                        percentPosition: false,
                        setGallerySize: false,
                        autoPlay: options["autoplay"],
                        pageDots: true,
                        wrapAround: false,
                        contain: true,
                        prevNextButtons: true
                    });
                } else {
                    $(this).flickity({
                        groupCells: '25%',
                        cellAlign: 'left',
                        contain: true,
                        pageDots: true,
                        wrapAround: true,
                        prevNextButtons: true
                    });
                }
                $(this).addClass("flickity-flex");
            });
        });
    });

    $.lui("lib_gallery", "[data-lib-fancybox]", function(selector) {
        bodyLoaded(function(){
            if ($(selector).find(".lib--fancybox-item").length) {
                $.importStyle(cdnjs.fancybox_css);
                $.importScript(cdnjs.fancybox, function(){
                    $(selector).each(function(){
                        let id = $(this).data("lib-fancybox");
                        $(this).fancybox({
                            selector: `[data-lib-fancybox="${id}"] .lib--fancybox-item`,
                            transitionEffect: "slide",
                            animationEffect: "zoom",
                            hideScrollbar: false,
                            buttons: [
                                "close"
                            ]
                        });
                    });
                });
            }
        });
    });

    (function fn_ajaxEvents() {
        doc.on('click', '[href].ajax', function (e) {
            e.preventDefault();
            let el = $(this);

            $.ajax({
                url: el.attr('href'),
                data: {ajax: +new Date},
                dataType: "json",
            }).done(function (payload) {
                fn_ajaxHandler(payload);
            });
        });

        if (typeof HTMLFormElement.prototype.reportValidity !== "undefined") {
            doc.on("click", "form[novalidate] button[type=submit]", function(e) {
                let form = $(this).closest("form");
                e.preventDefault();

                if (form[0].reportValidity()) {
                    form.trigger('submit');
                } else {
                    form.find(".part_ui_input").each(function () {
                        if (!$(this).filter("[data-no-validation]").length && !$(this).hasClass("state--invalid")) {
                            $(this).validateInput(true);
                        }
                    });

                    form.find(".part_ui_select.state--placeholder select[required]").each(function () {
                        $(this).parent().addClass("state--invalid");
                    });
                }
            });
        }

        doc.on('submit', 'form.ajax,form[data-component-ajax]', function (e) {
            e.preventDefault();
            let frm = $(this),
                formData = new FormData($(this)[0]);

            frm.find('button[type=submit]').addClass('state--loading').attr('disabled', true);

            $.ajax({
                method: frm.attr('method'),
                url: frm.attr('action'),
                data: formData,
                processData: false,
                contentType: false,
                dataType: "json",
            }).done(function (payload) {
                fn_ajaxHandler(payload,function() {
                    if(typeof payload.dialog !== 'undefined' && payload.dialog.length) {
                        lui_dialog.open(payload.dialog);
                    }
                });
            }).always(function () {
                frm.find('button[type=submit]').removeClass('state--loading').attr('disabled', false);
            });
        });

        doc.on('click', 'a[data-component-ajax]', function (e) {
            e.preventDefault();
            let url = $(this).attr('data-href');
            if (!url || !url.length) {
                url = $(this).attr('href');
            }

            if (!url || !url.length) {
                console.log('no href attribute for ajax component link');
                return;
            }

            $.ajax({
                url: url,
                method: 'post',
                dataType: 'json'
            }).done(function (payload) {
                fn_ajaxHandler(payload, function () {
                    //ScrollReveal().sync();
                });
            });
        });
    })();

    (function fn_pictureFill() {
        if (document.createElement('picture').toString().indexOf("HTMLPictureElement") === -1 && !html.hasClass("ie")) {
            $.importScript(cdnjs.picturefill);
        }
    })();

    (function fn_gridFill() {
        if (typeof html[0].style.grid !== 'string') {
            html.addClass("no-grid");
        }
    })();

    (function fn_cookieConsent() {
        if ($(".part_cookie_consent").length || $(".part_form_cookies").length) {
            $.importScript(cdnjs.cookieconsent);
        }
    })();

    setTimeout(function(){
        $.lui("lib_reCaptcha", "[data-lib-recaptcha]", function(selector) {
            selector.lib_reCaptcha("lib-recaptcha");
        });
    },2500);


    // nějaký sobčák mess kód, to chce pročistit

    $(document).on('click', '[data-category-change]', function(e){
        e.preventDefault();
        $('[data-category-change]').removeClass('state--active');
        $(this).addClass('state--active');
        $('#filterCategory').val($(this).data('category-change'));
        $('[data-catalog-filter-check]').prop('checked',false);
        priceRangeBackToDefaults();
        $('[data-toggle]').removeClass('state--active').next().hide();
        $('#filterPage').val('1').trigger('change', {pageChange: true});
    });

    $(document).on('click', '[data-cancel-filters]', function(e){
        e.preventDefault();
        $('[data-catalog-filter-check]').prop('checked',false);
        priceRangeBackToDefaults();
        $('[data-toggle]').removeClass('state--active').next().hide();
        $('#filterPage').val('1').trigger('change', {pageChange: true});
    });

    $(document).on('click', '[data-page-change]', function(e){
        e.preventDefault();
        $('#filterPage').val($(this).data('page-change')).trigger('change', {pageChange: true});
    });

    var request = null;

    $(document).on('change', '#filterForm :input', function(e, parm){
        if(!parm || parm.pageChange !== true) {
            $('#filterPage').val('1');
        }
        if(request != null) {
            request.abort();

        }
        request = $.ajax({
            data: $('#filterForm').serialize(),
            dataType: "html",
            url: window.location.href,
            success: function (payload) {
                let product = $(payload).find('#productsList');
                $(document).find('#productsList').html(product.html());

                let paginator = $(payload).find('#paginator');
                $(document).find('#paginator').html(paginator.html());
            }
        });
    });

    doc.on('click', '[data-category]', function (e) {
        $('[data-category]').removeClass('state--active');
        $(this).addClass('state--active');
    });
})(jQuery);

function clickUpperPhone() {
    fbq('init', '415919613120225');
    fbq('track', 'upper phone number click');
}

function clickBottomPhone() {
    fbq('init', '415919613120225');
    fbq('track', 'bottom phone number click');
}

function clickUpperEmail() {
    fbq('init', '415919613120225');
    fbq('track', 'upper email click');
}

function clickBottomEmail() {
    fbq('init', '415919613120225');
    fbq('track', 'bottom email click');
}

function clickUpperContact() {
    fbq('init', '415919613120225');
    fbq('track', 'upper contact click');
}

function clickBottomContact() {
    fbq('init', '415919613120225');
    fbq('track', 'bottom contact click');
}

function clickFormOdeslano() {
    fbq('init', '415919613120225');
    fbq('track', 'odeslano');
}

function clickInsertProductBasket() {
    fbq('init', '415919613120225');
    fbq('track', 'do kosiku klik jednotlive produkty');
}

function clickBasket() {
    fbq('init', '415919613120225');
    fbq('track', 'kosik klik');
}

function clickEndBasket() {
    fbq('init', '415919613120225');
    fbq('track', 'dokoncit objednavku');
}

function clickNewsletter() {
    fbq('init', '415919613120225');
    fbq('track', 'odoberat');
}