$.lui("comp", ".comp_products_detail", function(selector) {
    $.lib_flickity(function(){
        let slider = selector.find("[data-slider]").flickity({
            groupCells: '25%',
            cellAlign: 'left',
            contain: true,
            pageDots: true,
            wrapAround: true,
            prevNextButtons: true
        });

        slider.on( 'select.flickity', function( event, index ) {
            selector.find("[data-flickity-item]").removeClass("state--active");
            selector.find(".elm_gallery_thumbs > .col").eq(index).find("[data-flickity-item]").addClass("state--active");
        });

        selector.on("click", "[data-flickity-item]", function(){
            let elm = $(this);
            let index = elm.closest(".col").index();

            elm.closest(".elm_gallery_thumbs").find("[data-flickity-item]").removeClass("state--active");
            elm.addClass("state--active");

            slider.flickity( 'select', index );
        });
    });

    selector.on("click",".elm_comp_text [data-lib-anchor]",function () {
        selector.find(".wrp_comp_tabs").find(".part_tabs").find("button").first().trigger("click");
    })
});