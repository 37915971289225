$.lui("part", ".part_tabs", function(selector) {
    selector.on("click", ".part_ui_btn", function(){
        let elm = $(this).closest(".part_tabs"),
            inner = elm.find("ul"),
            inner_col = inner.find("li");

        inner.animate({scrollLeft: fn_col_width(elm.find(".state--active").parent().index())}, 800);

        function fn_col_width(index) {
            let width = 0;
            inner_col.each(function(i){
                if (i < index) {
                    width = width + $(this).outerWidth();
                }
            });

            return width;
        }
    });

    selector.each(function() {
        let elm = $(this),
            inner = elm.find("ul"),
            inner_col = inner.find("li"),
            inner_end = fn_col_width(inner_col.length);

        fn_nav_scroll();
        inner.on("scroll",fn_nav_scroll);

        inner.scrollLeft(fn_col_width(elm.find(".state--active").parent().index()));

        function fn_col_width(index) {
            let width = 0;
            inner_col.each(function(i){
                if (i < index) {
                    width = width + $(this).outerWidth();
                }
            });

            return width;
        }

        function fn_nav_scroll() {
            if (parseInt(inner.scrollLeft()+inner.width()) > inner_end-2) {
                elm.removeClass("is--state-start").addClass("is--state-end");
            }
            if (inner.scrollLeft() === 0) {
                elm.removeClass("is--state-end").addClass("is--state-start");
            }
        }
    });
});