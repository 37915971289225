function bodyLoaded(callback) {
    if (!document.body.classList.contains("body--loaded")) {
        let wait = setInterval(function(){
            if (document.body.classList.contains("body--loaded")) {
                clearInterval(wait);
                callback();
            }
        },1);
    } else {
        callback();
    }
}