$.lui("layout","#layout_header", function(selector) {
    let header = selector.find(".wrp_header_body");

    if (! $("#layout_nav").length) {
        selector.after('<nav id="layout_nav" class="ssm-nav"><div class="wrp_nav_head"></div><div class="wrp_nav_content"></div></nav>');

        $.importScript(cdnjs.touchswipe, function() {
            $('.ssm-nav').lui_ssm();
        });

        let layout_nav = $(document).find("#layout_nav"),
            logo = header.find(".elm_header_logo").clone(),
            nav = header.find(".elm_header_nav").clone(),
            links = selector.find(".elm_header_links").clone();

        layout_nav.find(".wrp_nav_head").append(logo);
        layout_nav.find(".wrp_nav_content").append(nav);
        layout_nav.find(".wrp_nav_content").append(links);

    }

    $.importScript(cdnjs.headroom, function(){
        let headroom = new Headroom(selector[0], {
            offset: 5,
            tolerance : {
                up : 5,
                down : 0
            },
            classes: {
                "initial" : "headroom",
                "pinned": "headroom--pinned",
                "unpinned": "headroom--unpinned",
                "top" : "headroom--top",
                "notTop" : "headroom--not-top"
            }
        });
        headroom.init();
    });

    selector.off("click.search");
    selector.on("click.search", `[data-click="toggleSearch"]`, function () {
        if (!selector.find(`[data-ref="headerSearchArea"]`).hasClass("state--active")) {
            selector.find(`[data-ref="headerSearchArea"]`).addClass("state--active").find("input").focus();

            if (win.width() < 960) {
                body.addClass("body--no-scroll");
            }
        } else {
            selector.find(`[data-ref="headerSearchArea"]`).removeClass("state--active");
            selector.find(`[data-ref="autocomplete-area"]`).removeClass("state--active");

            body.removeClass("body--no-scroll");
        }
    });
});